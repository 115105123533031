<template>
  <div
    v-if="filterPopularProfessions.length > 0"
    class="row"
  >
    <h2 class="heading-section-4">
      {{ source === SearchSource.Summaries ? 'Кандидаты' : 'Вакансии' }} по популярным профессиям
      <span class="text-primary">{{ city.titleIn??city.title }}</span>
    </h2>
    <nav>
      <ul class="d-flex flex-column flex-md-row flex-md-wrap gap-15 gap-md-25 list-unstyled">
        <li v-for="profession in filterPopularProfessions">
          <NuxtLink
            class="text-decoration-none text-dark"
            :to="getAlias + '/' + profession.alias"
            :title="getTitle(profession)"
          >
            {{ profession.title }}
          </NuxtLink>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup lang="ts">
import { SearchSource } from "~/composables/enums/SearchSource";
import type { PropType } from "vue";

//-----PROPS-----\\
const props = defineProps({
  city: { type: Object, required: true },
  source: { type: Number as PropType<SearchSource.Vacancies|SearchSource.Summaries>, required: true },
  profession: { type: String, default: undefined },
});

//-----VARIABLES-----\\

//-----STATE-----\\
const popularProfessions = ref([]);

//-----COMPUTED-----\\
const getAlias = computed(() => {
  let alias = `/${props.city.alias}/`;
  if (props.source === SearchSource.Vacancies) {
    alias += 'vacancies';
  } else if (props.source === SearchSource.Summaries) {
    alias += 'summaries';
  }

  return alias;
});
const filterPopularProfessions = computed(() => {
  if (props.profession === undefined) {
    return popularProfessions.value;
  }

  return popularProfessions.value.filter(item => {
    return item.alias !== props.profession;
  });
});

//-----ASYNC-----\\
await getPopularProfessions();

//-----METHODS-----\\
async function getPopularProfessions() {
  await mainFetch('professions', {
    query: {
      type: 'popular',
      limit: 12,
    }
  }).then((response) => {
    response = response.data.value;
    popularProfessions.value = response.professions;
  });
}
function getTitle(profession) {
  return (SearchSource.Vacancies ? 'Вакансии' : 'Кандидаты') + ' ' + profession.title + ' ' + (props.city.titleIn??props.city.title);
}
</script>

<style scoped lang="scss">

</style>